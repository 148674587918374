import React from "react";
import { SignInButton } from "../components/SignInButton";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function Login({ darkMode }) {
  return (
    <Box sx={{ height: "98vh" }}>
      <Grid container rowSpacing={5}>
        <Grid size={12}>
          <Box
            sx={{
              height: "10em",
              textAlign: "center",
              paddingTop: "20vh",
            }}
          >
            <img
              src={
                darkMode
                  ? "./objectslogo_login_white.png"
                  : "./objectslogo_login.png"
              }
              alt="logo"
              style={{ height: "8em" }}
            />
          </Box>
        </Grid>
        <Grid size={12} sx={{ textAlign: "center" }}>
          <SignInButton />
        </Grid>
      </Grid>
    </Box>
  );
}
