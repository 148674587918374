import React, { useContext } from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { SignOutButton } from "./SignOutButton";
import { ClientName, DarkMode } from "../context";

import "../css/layout.css";

export const PageLayout = (props) => {
  const { clientNameString } = useContext(ClientName);
  const { darkMode } = useContext(DarkMode);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className={darkMode ? "appbardark" : "appbar"}>
        <Toolbar>
          <Box sx={{ height: "4vh", display: "flex", paddingRight: "1vw" }}>
            <img
              src={"./objectslogo_small_white.png"}
              alt="logo"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {clientNameString ? clientNameString : null}
          </Typography>
          <SignOutButton />
        </Toolbar>
      </AppBar>
      <Box>{props.children}</Box>
    </Box>
  );
};
