import React, { useContext, useMemo } from "react";
import { Box, Button, Tab, Tabs, Grid2 } from "@mui/material";
import PropTypes from "prop-types";
import { PageLocation, DarkMode, UserPermissions } from "../context";
import Onboarding from "../components/Onboarding";
import Whitelist from "../components/Whitelist";
import Access from "../components/Access";
import { ItemDragDrop } from "../ItemDragDrop";
import "../css/configpage.css";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ConfigPage() {
  const dropitemtracking = useMemo(() => new ItemDragDrop(), []);
  const { setPageLocation } = useContext(PageLocation);
  const { userPermissions } = useContext(UserPermissions);
  const { darkMode } = useContext(DarkMode);
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Grid2 container>
          <Grid2 size={{ xs: 12, xl: 0.5 }}>
            <Button
              className={darkMode ? "buttonbackdark" : "buttonback"}
              onClick={() => setPageLocation("dashboard")}
              color="primary"
            >
              Back
            </Button>
          </Grid2>
          {userPermissions?.config && (
            <Grid2 size={{ xs: 12, xl: 11 }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="config section"
              >
                <Tab
                  label="Access"
                  {...a11yProps(0)}
                  className={darkMode ? "tabsdark" : "tabs"}
                />
                {userPermissions?.onboarding && (
                  <Tab
                    label="Onboarding"
                    {...a11yProps(1)}
                    className={darkMode ? "tabsdark" : "tabs"}
                  />
                )}
                {userPermissions?.onboarding && (
                  <Tab
                    label="Whitelist"
                    {...a11yProps(2)}
                    className={darkMode ? "tabsdark" : "tabs"}
                  />
                )}
              </Tabs>
            </Grid2>
          )}
        </Grid2>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <Access dropitemtracking={dropitemtracking} />
      </CustomTabPanel>
      {userPermissions?.onboarding && (
        <CustomTabPanel value={tabValue} index={1}>
          <Onboarding />
        </CustomTabPanel>
      )}
      {userPermissions?.onboarding && (
        <CustomTabPanel value={tabValue} index={2}>
          <Whitelist />
        </CustomTabPanel>
      )}
    </Box>
  );
}
