import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Box,
  List,
  ListItem,
  CircularProgress,
  Typography,
  Tooltip,
  IconButton,
  Grid2,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  OutlinedInput,
  CardActions,
} from "@mui/material";
import FallStreakAPI from "../api/FallStreakApi";
import { DarkMode, TokenData, NewAlert } from "../context";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";

export default function Whitelist() {
  const { darkMode } = useContext(DarkMode);
  const { tokenData } = useContext(TokenData);
  const { setNewAlert } = useContext(NewAlert);
  const [whitelistData, setWhitelistData] = useState();
  const [whitelistAddOpen, setWhitelistAddOpen] = useState(false);
  const [domainName, setDomainName] = useState("");

  const WhitelistInfo = useCallback(() => {
    FallStreakAPI({ token_data: tokenData }, "POST", "whitelist", "")
      .then(function (response) {
        if (response?.status === 200) {
          if (response?.data) {
            setWhitelistData(response?.data);
          }
        } else if (response?.status === 401) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        } else if (response?.status === 403) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [setWhitelistData, tokenData, setNewAlert]);

  function SaveToWhitelist() {
    FallStreakAPI(
      {
        token_data: { token_data: tokenData },
        domain_details: { domain_name: domainName },
      },
      "PUT",
      "whitelist",
      ""
    )
      .then(function (response) {
        if (response?.status === 200) {
          if (response?.data) {
            setWhitelistData();
            setNewAlert({
              color: "success",
              message: response?.data + " whitelisted",
            });
          }
          handleWhitelistAddClose();
        } else if (response?.status === 401) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        } else if (response?.status === 403) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  useEffect(() => {
    !whitelistData && WhitelistInfo();
  }, [WhitelistInfo, whitelistData]);

  function handleWhitelistAddClose() {
    setDomainName("");
    setWhitelistAddOpen(false);
  }

  return (
    <Box>
      <Grid2 container>
        <Grid2 size={12}>
          <Typography
            variant="h5"
            component="div"
            sx={{ color: darkMode ? "#ffffff" : "#001864" }}
          >
            {"Whitelisted Clients"}
            <Tooltip title="Refresh Whitelist">
              <IconButton sx={{ mb: "0px" }} onClick={() => setWhitelistData()}>
                <RefreshTwoToneIcon
                  className={
                    darkMode ? "refreshwhitelistdark" : "refreshwhitelist"
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add to Whitelist">
              <IconButton
                sx={{ mb: "0px" }}
                onClick={() => setWhitelistAddOpen(true)}
              >
                <AddCircleTwoToneIcon
                  className={darkMode ? "addiconconfigdark" : "addiconconfig"}
                />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          <List
            key={"fullwhitelist"}
            dense={true}
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "transparent",
              position: "relative",
              overflow: "auto",
              maxHeight: "55vh",
              "& ul": { padding: 0 },
            }}
          >
            {whitelistData ? (
              whitelistData.map((value, index) => {
                return (
                  <ListItem
                    key={index}
                    sx={{ color: darkMode ? "#ffffff" : "#001864" }}
                  >
                    {value?.["domain"]}
                  </ListItem>
                );
              })
            ) : (
              <CircularProgress />
            )}
          </List>
        </Grid2>
      </Grid2>
      <Dialog open={whitelistAddOpen} onClose={handleWhitelistAddClose}>
        <Card className={darkMode ? "appcardsdark" : "appcards"}>
          <CardHeader
            title="Add to whitelist"
            className={darkMode ? "appcardstitledark" : "appcardstitle"}
          />
          <CardContent>
            <Grid2 container rowGap={2}>
              Domain Name
              <Grid2 size={12}>
                <OutlinedInput
                  placeholder="Enter the domain"
                  value={domainName}
                  onChange={(e) => setDomainName(e.target.value)}
                  sx={{
                    color: darkMode ? "white " : "#001864",
                    width: "100%",
                  }}
                />
              </Grid2>
            </Grid2>
          </CardContent>
          <CardActions>
            {domainName !== "" ? (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Tooltip
                  title="Save to whitelist"
                  sx={{ borderStyle: "solid" }}
                >
                  <IconButton onClick={() => SaveToWhitelist()}>
                    <SaveTwoToneIcon
                      className={
                        darkMode ? "saveiconconfigdark" : "saveiconconfig"
                      }
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : null}
          </CardActions>
        </Card>
      </Dialog>
    </Box>
  );
}
