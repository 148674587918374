import React from "react";
import { Box, Button, Typography } from "@mui/material";
import "../css/notfound.css";

export default function ErrorPage({ errorPageDetails }) {
  function ReloadPage() {
    window.location.reload();
    return false;
  }

  return (
    <Box className="notfoundBox">
      {errorPageDetails.map((value, index) => {
        return (
          <Typography
            variant="h4"
            component="div"
            color="primary"
            key={index}
            sx={{
              textAlign: "center",
              marginTop: "1em",
            }}
          >
            {value}
          </Typography>
        );
      })}
      <Button
        variant="outlined"
        onClick={() => ReloadPage()}
        color="primary"
        sx={{ marginTop: "5vh" }}
      >
        Refresh Site
      </Button>
    </Box>
  );
}
