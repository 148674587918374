import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Box,
  List,
  ListItem,
  CircularProgress,
  ListItemText,
  Typography,
} from "@mui/material";
import FallStreakAPI from "../api/FallStreakApi";
import { DarkMode, TokenData, NewAlert } from "../context";

export default function Onboarding() {
  const { darkMode } = useContext(DarkMode);
  const { tokenData } = useContext(TokenData);
  const { setNewAlert } = useContext(NewAlert);
  const [onboardingData, setOnboardingData] = useState();

  const OnboardingInfo = useCallback(() => {
    FallStreakAPI({ token_data: tokenData }, "POST", "onboarding", "")
      .then(function (response) {
        if (response?.status === 200) {
          if (response?.data) {
            setOnboardingData(response?.data);
          }
        } else if (response?.status === 401) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        } else if (response?.status === 403) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [setOnboardingData, tokenData, setNewAlert]);

  useEffect(() => {
    !onboardingData && OnboardingInfo();
  }, [OnboardingInfo, onboardingData]);

  return (
    <Box>
      <Typography
        variant="h5"
        component="div"
        sx={{ color: darkMode ? "#ffffff" : "#001864" }}
      >
        {"Activation Pending"}
      </Typography>
      <List>
        {onboardingData?.[0]?.pendingusers ? (
          onboardingData.map((value, index) => {
            return (
              <ListItem key={index}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      variant="body1"
                      className={
                        darkMode ? "entralistitemdark" : "entralistitem"
                      }
                    >
                      {value?.id}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      className={
                        darkMode ? "entralistitemdark" : "entralistitem"
                      }
                    >
                      {value?.pendingusers.map((username) => {
                        return username + " ";
                      })}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })
        ) : onboardingData?.[0] ? (
          <ListItem
            key={"nopending"}
            className={darkMode ? "entralistitemdark" : "entralistitem"}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="body1"
                  className={darkMode ? "entralistitemdark" : "entralistitem"}
                >
                  {"There are no clients pending activation"}
                </Typography>
              }
            />
          </ListItem>
        ) : (
          <CircularProgress />
        )}
      </List>
    </Box>
  );
}
